import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PlaywireAdUnit } from '@resources/js/typings/viewModels/playwireAdViewModel';
import { ScriptLoadStatus } from '@resources/js/hooks/useScript';
import { AdPlacementPlaywireType } from '@resources/js/typings/viewModels/adPlacements';

type AdUnitSlot = PlaywireAdUnit & {
  elementId: string;
};

type ActiveAdSlot = AdUnitSlot & {
  element: HTMLElement;
};

export type UserProfile = {
  format: 'UUP1' | 'EUP1';
  eid: string;
};

type PageOSBusEvent = 'trendi_video_ad_started';
type PageOS = {
  BUS?: {
    on: (event: PageOSBusEvent, callback: () => void) => void;
    off: (event: PageOSBusEvent, callback: () => void) => void;
    after: (event: PageOSBusEvent, callback: () => void) => void;
  };
};

type Ramp = {
  addTag?: (tag: string) => Promise<void>;
  addUnits?: (units: PlaywireAdUnit[]) => Promise<void>;
  getUnits?: () => AdPlacementPlaywireType[];
  destroyUnits?: (units: 'all' | string) => Promise<void>;
  displayUnits?: () => Promise<void>;
  settings?: {
    slots: Record<string, ActiveAdSlot>;
  };
  processPage?: (
    path?: string,
    unitsExcluded?: Array<AdPlacementPlaywireType>
  ) => Promise<void>;
  blockRefresh?: (unit: PlaywireAdUnit | 'ALL') => void;
  passiveMode?: boolean;
  que: (() => void)[];
  forcePath?: string;
  onReady?: () => void;
  up?: UserProfile;
};

declare global {
  interface Window {
    // provider managed
    ramp: Ramp;
    PageOS?: PageOS;
  }
}

export const log = (...rest: unknown[]) => console.info('[Playwire]', ...rest);

const display = async (adUnit: PlaywireAdUnit): Promise<void> => {
  if (!window.ramp) return;

  log('display', adUnit);
  try {
    await window.ramp.addUnits?.([adUnit]);
    await window.ramp.displayUnits?.();
  } catch (e) {
    log('Failed to display', e);
  }
};

const destroy = async (adUnit: PlaywireAdUnit): Promise<void> => {
  if (!window.ramp) return;

  log('destroy', adUnit.type);
  Object.entries(window.ramp.settings?.slots ?? {})
    .map(([slotName, slot]) => {
      return { slotName, slot };
    })
    .filter(
      ({ slotName, slot }) =>
        slotName === adUnit.type ||
        slot.type === adUnit.type ||
        slot.element?.parentElement?.id === adUnit.selectorId
    )
    .forEach(({ slotName }) => window.ramp.destroyUnits?.(slotName));
};

export type PlaywireScriptContext = {
  scriptStatus: ScriptLoadStatus;
  onRampReady: () => void;
};

type PlaywireContext = {
  isLoading: boolean;
  isAvailable: boolean;
  isEnabled: boolean;
  destroy: (adUnit: PlaywireAdUnit) => Promise<void>;
  display: (adUnit: PlaywireAdUnit) => Promise<void>;
};

export type PlaywireScriptViewModel = {
  publisherId: string;
  siteId: string;
};

export type PlaywireAdsViewModel = {
  shouldShowAds: boolean;
  publisherId: string;
  siteId: string;
  googleAnalyticsTag: string;
  userProfile?: UserProfile | null;
  forcePath?: string;
};

type Props = Pick<PlaywireAdsViewModel, 'forcePath' | 'userProfile'> & {
  children: ReactNode;
  usePlaywireScript: () => PlaywireScriptContext;
};

export const Context = createContext<PlaywireContext>({
  destroy: async () => {},
  display: async () => {},
  isLoading: false,
  isAvailable: false,
  isEnabled: false,
});

export const PlaywireAdsContextProvider = ({
  usePlaywireScript,
  forcePath,
  userProfile,
  children,
}: Props): JSX.Element => {
  const log = (...rest: unknown[]) => console.info('[Playwire]', ...rest);

  const { scriptStatus, onRampReady } = usePlaywireScript();
  const [isLoading, setIsLoading] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);
  const isInitialized = useRef(false);
  const isEnabled = !isLoading && isAvailable;

  useEffect(() => {
    if (isInitialized.current) return;
    log('Init', { forcePath, userProfile });
    window.ramp = window.ramp || {};
    window.ramp.que = window.ramp.que || [];
    window.ramp.passiveMode = true;
    window.ramp.forcePath = forcePath ?? undefined;
    window.ramp.up = userProfile ?? undefined;
    window.ramp.que.push(() => {
      setIsLoading(false);
      setIsAvailable(true);
      onRampReady();
    });
    isInitialized.current = true;
  }, [onRampReady, forcePath, userProfile]);

  useEffect(() => {
    switch (scriptStatus) {
      case 'ready':
        setTimeout(() => {
          // It's possible the ready onReady event didn't fire (dev domain only?)
          setIsLoading(false);
        }, 3000);
        break;
      case 'error':
        setIsLoading(false);
        setIsAvailable(false);
        break;
    }
  }, [scriptStatus]);

  return (
    <Context.Provider
      value={{
        isEnabled,
        isLoading,
        isAvailable,
        display,
        destroy,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const usePlaywire = (): PlaywireContext => useContext(Context);

export default usePlaywire;
