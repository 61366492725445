import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

type CookieKey = string;

export function useCookie<T>(
  name: CookieKey,
  defaultValue: T,
  options?: Cookies.CookieAttributes
) {
  const getCookieState = (): T => {
    const savedState = Cookies.get(name);
    try {
      return {
        ...defaultValue,
        ...(savedState ? (JSON.parse(savedState) as T) : {}),
      };
    } catch (e) {
      Cookies.remove(name);
      return defaultValue;
    }
  };

  const [cookie, setCookieInternal] = useState(() => getCookieState());

  const setCookie = useCallback(
    (value: T | ((prevState: T) => T)): void => {
      setCookieInternal((storedValue) => {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        Cookies.set(name, JSON.stringify(valueToStore), options);
        return valueToStore;
      });
    },
    [name, options]
  );

  return [cookie, setCookie] as const;
}
