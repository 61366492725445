import React, {
  ReactNode,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from 'react';
import { useCookie } from '@resources/js/hooks/useCookie';
import { onPageChanged } from '@resources/js/global/lib/pageEvents';
import { addYears } from 'date-fns';

// Keep in rough sync with app/Http/Middleware/TrackAdBlock.php
type MetricCounter =
  | 'pageViews'
  | 'direct'
  | 'video'
  | 'blocked'
  | 'house'
  | 'fallback'
  | 'blockThrough';

type MeasuredMetricCounter = Exclude<MetricCounter, 'pageViews'>;

// [0, 3) No Ads Allowed (uBlock Origin)
// [3, 4) Acceptable Ads Allowed (Default Adblock and Adblock Plus Settings)
// [4, 5] All Ads Allowed (No Adblocker)
export const metricScoreMap: Record<MeasuredMetricCounter, number> = {
  blocked: 0, // AB/ABP with no acceptable ads
  fallback: 1,
  house: 2,
  blockThrough: 3,
  direct: 4,
  video: 5,
};

const hasAdBlockCapability = (score: number): boolean => score <= 3.2;

const isEligibleForAdsLite = (isAdBlocking: boolean, score: number): boolean =>
  isAdBlocking && score <= 2.5;

type CookieData = {
  lastResetAt: number;
  hasAdBlockCapability: boolean;
  isAdLiteEligible: boolean;
  lastChangeAt: number;
  recent: Array<number>;
} & Record<MetricCounter, number>;

type AdBlockMetrics = CookieData & {
  increment: (metric: MetricCounter) => void;
  score: number;
  reset: () => void;
};

export const AdBlockContext = createContext<AdBlockMetrics | undefined>(
  undefined
);

type AdBlockContextProviderProps = {
  cookieName: string;
  children: ReactNode;
};

const now = new Date().getTime();
const defaultCookieData: CookieData = {
  pageViews: 0,
  direct: 0,
  video: 0,
  blocked: 0,
  blockThrough: 0,
  house: 0,
  fallback: 0,

  hasAdBlockCapability: false,
  isAdLiteEligible: false,

  lastResetAt: now,
  lastChangeAt: now,

  recent: [],
};
const cookieOptions = {
  expires: addYears(now, 1),
};

const maxRecent = 40;

export const AdBlockMetricsContextProvider = ({
  cookieName,
  children,
}: AdBlockContextProviderProps): JSX.Element => {
  const [cookieData, setCookie] = useCookie<CookieData>(
    cookieName,
    defaultCookieData,
    cookieOptions
  );

  const score =
    cookieData.recent.length < maxRecent
      ? 5.0
      : cookieData.recent.reduce((acc, val) => acc + val, 0) /
        cookieData.recent.length;

  useEffect(() => {
    if (score === 5.0) {
      // Not enough data to make a decision
      return;
    }
    if (new Date().getTime() - cookieData.lastChangeAt > 300 * 1000) {
      setCookie((prev) => {
        const isAdBlocking =
          prev.hasAdBlockCapability || hasAdBlockCapability(score);

        return {
          ...prev,
          hasAdBlockCapability: isAdBlocking,
          isAdLiteEligible: isEligibleForAdsLite(isAdBlocking, score),
          lastChangeAt: new Date().getTime(),
        };
      });
    }
  }, [cookieData.lastChangeAt, score, setCookie]);

  useEffect(() => {
    setCookie((prev) => ({
      ...prev,
      pageViews: prev.pageViews + 1,
    }));
    return onPageChanged(() => {
      setCookie((prev) => ({
        ...prev,
        pageViews: prev.pageViews + 1,
      }));
    });
  }, [setCookie]);

  const increment = useCallback(
    (metric: MetricCounter) => {
      setCookie((prev) => ({
        ...prev,
        [metric]: prev[metric] + 1,
        ...(metric !== 'pageViews'
          ? {
              recent: [metricScoreMap[metric], ...prev.recent].slice(
                0,
                maxRecent
              ),
            }
          : {}),
      }));
    },
    [setCookie]
  );

  const reset = useCallback(() => {
    setCookie(defaultCookieData);
  }, [setCookie]);

  return (
    <AdBlockContext.Provider
      value={{
        ...cookieData,
        score,
        increment,
        reset,
      }}
    >
      {children}
    </AdBlockContext.Provider>
  );
};

const useAdBlockMetrics = (): AdBlockMetrics => {
  const context = useContext(AdBlockContext);
  if (context === undefined) {
    throw new Error(
      'useAdBlockMetrics must be used within an AdBlockMetricsContextProvider'
    );
  }
  return context;
};

export default useAdBlockMetrics;
