import onDOMContentLoaded from './onDOMContentLoaded';

type EventCallback = (event: Event) => void;
type UnregisterCallback = () => void;
type EventRegistrar = (callback: EventCallback) => UnregisterCallback;

// events might be fired before DOMContentLoaded, so listen early
const onEvent = (eventName: string): EventRegistrar => {
  return (callback: EventCallback): UnregisterCallback => {
    const listener = (event: Event) =>
      onDOMContentLoaded(() => callback(event));
    window.addEventListener(eventName, listener);

    return (): void => {
      window.removeEventListener(eventName, listener);
    };
  };
};

export const onPageChanged = onEvent('page-changed');
