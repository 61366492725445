import React, { ReactNode, useContext, createContext } from 'react';
import { AdPlacementsViewModel } from '@resources/js/typings/viewModels/adPlacements';

export const AdPlacementsContext = createContext<AdPlacementsViewModel>({});

type AdPlacementsContextProviderProps = {
  children: ReactNode;
  adPlacements: AdPlacementsViewModel;
};

export const AdPlacementsContextProvider = ({
  children,
  adPlacements = {},
}: AdPlacementsContextProviderProps): JSX.Element => (
  <AdPlacementsContext.Provider value={adPlacements}>
    {children}
  </AdPlacementsContext.Provider>
);

const useAdPlacements = (): AdPlacementsViewModel =>
  useContext(AdPlacementsContext);

export default useAdPlacements;
