import React, {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import Script from 'next/script';
import useLoadAdScripts from '../hooks/useLoadAdScripts';

import {
  PlaywireAdsViewModel,
  PlaywireScriptContext,
} from '@resources/js/contexts/usePlaywire';
import { ScriptLoadStatus } from '@resources/js/hooks/useScript';
import { useRouter } from 'next/router';

export const Context = createContext<PlaywireScriptContext | undefined>(
  undefined
);

type Props = Pick<
  PlaywireAdsViewModel,
  'publisherId' | 'siteId' | 'googleAnalyticsTag'
> & {
  children: ReactNode;
};

const log = (...rest: unknown[]) => console.info('[PlaywireArchon]', ...rest);

export const PlaywireScriptForArchon = ({
  publisherId,
  siteId,
  googleAnalyticsTag,
  children,
}: Props): JSX.Element => {
  const isValidConfig = publisherId !== '' && siteId !== '';
  const isEnabled = useLoadAdScripts() && isValidConfig;
  const router = useRouter();
  const [scriptStatus, setScriptStatus] = useState<ScriptLoadStatus>(
    isEnabled ? 'loading' : 'idle'
  );

  const sendPageView = useCallback(() => {
    const path = window.location.pathname + window.location.search;
    window.ramp.processPage?.(path, window.ramp.getUnits?.() || []).then(() =>
      log('Process Page Triggered', {
        googleAnalyticsTag,
        path,
      })
    );
  }, [googleAnalyticsTag]);

  useEffect(() => {
    if (!isEnabled || scriptStatus !== 'ready') return;
    router.events.on('routeChangeComplete', sendPageView);

    return () => {
      router.events.off('routeChangeComplete', sendPageView);
    };
  }, [
    googleAnalyticsTag,
    scriptStatus,
    isEnabled,
    router.events,
    sendPageView,
  ]);

  const onRampReady = () => {
    gtag('config', googleAnalyticsTag, { send_page_view: false });
    gtag('event', 'ramp_js', {
      send_to: googleAnalyticsTag,
      pageview_id: Date.now().toString(),
    });
    log('Google Analytics Configured', { googleAnalyticsTag });
    log('Initial Page View Sent as part of loading Ramp');
  };

  return (
    <Context.Provider
      value={{
        scriptStatus,
        onRampReady,
      }}
    >
      {isEnabled && (
        <Script
          strategy='afterInteractive'
          src={`https://cdn.intergient.com/${publisherId}/${siteId}/ramp.js`}
          onLoad={() => setScriptStatus('ready')}
          onError={() => setScriptStatus('error')}
        />
      )}
      {children}
    </Context.Provider>
  );
};

const usePlaywireScriptForArchon = (): PlaywireScriptContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      'usePlaywireScriptForArchon must be used within a PlaywireScriptForArchon'
    );
  }
  return context;
};

export default usePlaywireScriptForArchon;
