const logAnalyticsEvent = ({
  analyticsComponentName,
  eventName,
  value,
  isInteraction,
  extraParameters,
}: SendEventParameters): void => {
  console.log(
    `[analytics] ${analyticsComponentName}.${eventName}` +
      (value ? ` => ${value}` : ''),
    Object.fromEntries(
      Object.entries({ isInteraction, ...extraParameters }).filter(
        ([, value]) => value !== undefined
      )
    )
  );
};

export const sendPageView = (properties: {
  page_title: string;
  page_path: string;
  page_location?: string;
}): void => {
  window.gtag('event', 'page_view', properties);
};

export type SendEventParameters = {
  analyticsComponentName: string;
  eventName: string;
  label?: string;
  value?: number;
  isInteraction?: boolean;
  extraParameters?: EventExtraParameters;
};

/*
  Try to re-use existing properties here instead of adding new ones.
  If we have to add new ones, it should be a team discussion.
  New properties need to be added as custom dimensions in Google Analytics.
  We are only allowed up to 50 custom dimensions.
 */
export type EventExtraParameters = {
  debug_mode?: unknown;
  new_value?: unknown;
  previous_value?: unknown;
  zone_id?: unknown;
  difficulty?: unknown;
  encounter_id?: unknown;
  page_number?: unknown;
  filter_1?: unknown;
  filter_2?: unknown;
  filter_3?: unknown;
  count?: unknown;
  duration?: unknown;
  size?: unknown;
  game?: unknown;
};

declare global {
  interface Window {
    gameSiteTitle: string;
    dataLayer: ({ event?: string } & Record<
      string,
      number | string | boolean | undefined | unknown
    >)[];
    userProperties?: Record<string, unknown>;
  }
}

/**
 * To enable lightweight analytics event logging,
 *   window.localStorage.setItem('log.analytics.sendEvent', 'true')
 * There will still be some batching and delay seen in the GA4 DebugView, but if
 * you are only interested in seeing what we send as you interact with a page,
 * it's much more readable as one/two lines than the pages of debug info the Google
 * Analytics extension logs.
 */
export function sendEvent(
  {
    analyticsComponentName,
    eventName,
    label = undefined,
    value = undefined,
    isInteraction = true,
    extraParameters = {},
  }: SendEventParameters,
  manual = false // should not be used outside of desktop client
): void {
  if (typeof window === 'undefined') return;

  const fullEventName = `${analyticsComponentName}_${eventName}`;

  if (fullEventName.length > 40) {
    console.warn(
      `Analytics event name gets truncated at 40 characters ("${fullEventName}")`
    );
  }

  if (window.localStorage.getItem('log.analytics.sendEvent')) {
    logAnalyticsEvent({
      analyticsComponentName,
      eventName,
      label,
      value,
      isInteraction,
      extraParameters,
    });

    // [Monitor events in DebugView](https://support.google.com/analytics/answer/7201382)
    extraParameters.debug_mode = true;
  }

  if (manual) {
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      event: fullEventName,
      event_category: analyticsComponentName,
      event_label: label,
      value: value,
      non_interaction: !isInteraction,
      ...extraParameters,
      site_title: window.gameSiteTitle,
    });

    return;
  }

  try {
    window.gtag('event', fullEventName, {
      event_category: analyticsComponentName,
      event_label: label,
      value: value,
      non_interaction: !isInteraction,
      ...extraParameters,
      site_title: window.gameSiteTitle,
    });
  } catch {
    // ignore
  }
}
